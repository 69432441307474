import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Layout/Layout.js";
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons';
import SEO from '../components/SEO/seo';
import TransitionLink from '../components/TransitionLink/TransitionLink';
import ExternalLink from '../components/ExternalLink/ExternalLink';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO title="Home" mdxType="SEO" />
    <h1>{`Hi there!`}</h1>
    <p>{`My name is Mandy Mak. I'm a software engineer based in NYC, making cool things with awesome people at `}<ExternalLink href="https://www.crunchbase.com/" text="Crunchbase" mdxType="ExternalLink" />{`.`}</p>
    <p>{`Before I became an engineer, I taught introductory philosophy courses at `}<ExternalLink href="https://sfsu.edu/" text="SFSU" mdxType="ExternalLink" />{`. I've also been a barista, a BBQ restaurant manager, and a field sales rep.`}</p>
    <p>{`When I'm not programming or spending time with loved ones, I enjoy reading, making Jesmonite coasters, and playing tennis. I love taking advantage of all that NYC has to offer, especially enjoying various forms of live entertainment, trying new cuisines, and taking long walks in Central Park.`}</p>
    <p>{`I use this website as a sandbox to learn and play. While you are here, you can see what I'm doing `}<TransitionLink to="/now" mdxType="TransitionLink">{`now`}</TransitionLink>{` and check out books that I've been `}<TransitionLink to="/reading" mdxType="TransitionLink">{`reading`}</TransitionLink>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      